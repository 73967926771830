
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import _ from "lodash";
import helpers from "@/helpers/global";
import MainCardReults from "@/components/MainCardResults.vue";

import { getDatabase, get, query, ref } from "firebase/database";

export default defineComponent({
  name: "Candidate",
  components: {
    MainCardReults,
  },
  title() {
    let name = "";
    if (this.data) {
      let newName =
        (this as any).data.firstname + " " + (this as any).data.lastname;
      if ((this as any).data.firstname && (this as any).data.lastname) {
        name = newName.trim() + " | ";
      }
    }
    return `${name}My Culture`;
  },
  watch: {
    error(value) {
      console.error(value);
    },
  },
  data() {
    return {
      lang: "en",
      disabledRating: true,
      isRespondent: true,
      assessmentsName: "Culture Fit for Jan 2022 Batch",
      culturePreferenceRanderChart: false,
      //*
      culturePreferenceFocus: {
        value: 82,
        title: "Overall",
        id: "Overall",
        status: "green",
        description:
          "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
        candidate: {
          clan: 12,
          create: 12,
          complete: 14,
          control: 11,
        },
        company: {
          clan: 11,
          create: 13,
          complete: 13,
          control: 11,
        },
      },
      //*/
      focusUser: {
        id: "no_user",
        name: "-",
        firstname: "-",
        lastname: "-",
        companyName: "-",
        email: "=",
        status: "Completed",
        joined: "=",
        completed: "=",
        score: "-",
        yourRating: 0,
        valuesAndTraits: {
          overall: "72%",
          values: "88%",
          valueTags: [
            {
              label: "Integrity",
              id: "Integrity",
              status: "green",
            },
            {
              label: "Entrepreneurship",
              id: "Entrepreneurship",
              status: "green",
            },
            {
              label: "Respect",
              id: "Respect",
              status: "green",
            },
            {
              label: "Honesty",
              id: "Honesty",
              status: "yellow",
            },
            {
              label: "Excellence",
              id: "Excellence",
              status: "yellow",
            },
            {
              label: "Growth",
              id: "Growth",
              status: "yellow",
            },
            {
              label: "Winning",
              id: "Winning",
              status: "red",
            },
            {
              label: "Collaboration",
              id: "Collaboration",
              status: "red",
            },
          ],
          traits: "72%",
          traitTags: [
            {
              label: "Integrity",
              id: "Integrity",
              status: "green",
            },
            {
              label: "Entrepreneurship",
              id: "Entrepreneurship",
              status: "green",
            },
            {
              label: "Respect",
              id: "Respect",
              status: "green",
            },
            {
              label: "Honesty",
              id: "Honesty",
              status: "green",
            },
            {
              label: "Excellence",
              id: "Excellence",
              status: "yellow",
            },
            {
              label: "Growth",
              id: "Growth",
              status: "yellow",
            },
            {
              label: "Winning",
              id: "Winning",
              status: "yellow",
            },
            {
              label: "Collaboration",
              id: "Collaboration",
              status: "red",
            },
          ],
        },
        culturePreference: {
          overall: "76%",
          overallStatus: "green",
          data: [
            {
              value: "82%",
              title: "Overall",
              id: "Overall",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "82%",
              title: "Dominant Characteristics",
              id: "Dominant Characteristics",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "81%",
              title: "Organizational Leadership",
              id: "Organizational Leadership",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "79%",
              title: "Management of Employees",
              id: "Management of Employees",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "75%",
              title: "Organization Glue",
              id: "Organization Glue",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "70%",
              title: "Strategic Emphases",
              id: "Strategic Emphases",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "59%",
              title: "Criteria of Success",
              id: "Criteria of Success",
              status: "red",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
          ],
        },
        acceptableBehaviors: {
          overall: "76%",
          scenarioData: [
            {
              id: "soelja-5ae58x-a41ed",
              title: "Scenario 1",
              description: "Regularly showing up 5-min late to work",
              matchingStatus: true,
              companyValueLabel: "Frowned Upon",
              companyStatus: "green",
              userValueLabel: "Frowned Upon",
              userStatus: "green",
            },
            {
              id: "soelja-5a2e58x-a41e2d",
              title: "Scenario 2",
              description:
                "Unresponsive over chat for most of the day without an away status.",
              matchingStatus: false,
              companyValueLabel: "Frowned Upon",
              companyStatus: "green",
              userValueLabel: "Acceptable",
              userStatus: "red",
            },
            {
              id: "soelja-5ae58x-a41ed",
              title: "Scenario 1",
              description:
                "Regularly showing up 5-min late to work, It is description too longgggggggggggggggg ggggggggg woard on here and show what is going on, testset settset",
              matchingStatus: true,
              companyValueLabel: "Frowned test",
              companyStatus: "yellow",
              userValueLabel: "Frowned test",
              userStatus: "yellow",
            },
          ],
        },
      },
      culturePreferenceChartTag: "",
      // radarChartVal: {},
    };
  },
  computed: {
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      // const userDataEncoded: any = localStorage.getItem("userData");
      // if (userDataEncoded) {
      //   try {
      //     const userData = JSON.parse(atob(userDataEncoded) || "{}");
      //     if (userData.email) {
      //       return companyLogoUrl;
      //     } else {
      //       return defaultLogoUrl;
      //     }
      //   } catch (e: any) {
      //     console.error(e);
      //   }
      // } else {
      //   return defaultLogoUrl;
      // }
      return companyLogoUrl || defaultLogoUrl;
    },
    error() {
      return this.$store.getters["assessment/error"];
    },
    loading() {
      return this.$store.getters["assessment/loading"];
    },
    data() {
      return this.$store.getters["assessment/data"] || {};
    },
    formTemplate() {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      return formTemplate;
    },
    summaries() {
      const summaries = this.$store.getters["assessment/summaries"] || {};

      // this view want to sort values and traits by score
      const hasOwnProperty = Object.prototype.hasOwnProperty;
      if (hasOwnProperty.call(summaries, "values_and_traits")) {
        for (const i in summaries.values_and_traits.scenarios) {
          const scenario = summaries.values_and_traits.scenarios[i];
          const questions = scenario.questions;
          const name = _.snakeCase(scenario.name);
          const values = summaries.values_and_traits.candidate[name];
          questions.sort((a: any, b: any) => {
            const scoreA = values[a.id];
            const scoreB = values[b.id];
            return scoreB - scoreA;
          });
        }
      }
      return summaries;
    },
    companyColor() {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
  },
  async mounted() {
    const valid = this.checkPropsId();
    if (valid) {
      await this.initialize();
    }
    this.$store.dispatch("forceReady", null, { root: true });
  },
  methods: {
    async initialize() {
      let _id;
      try {
        const u_id = String(this.$router.currentRoute.value.query.u_id);
        _id = decodeURIComponent(atob(u_id));
      } catch (e) {
        console.log(e, "e");
        this.$router.replace(`/404`);
      }

      await this.$store.dispatch(
        "assessment/getFormTemplate",
        { _id },
        {
          root: true,
        }
      );

      if (this.formTemplate && this.formTemplate.creator) {
        const email = this.formTemplate.creator;
        const emailKey = helpers.emailKey(email);
        const db = getDatabase();
        const path = `users/${emailKey}`;
        const user = (await get(query(ref(db, path)))).val();
        if (user.companyLogoUrl) {
          this.$store.dispatch("user/applyCompanyLogoUrl", user, {
            root: true,
          });
        }
      }

      // for graph rendering
      this.focusUser.firstname = this.data.firstname;
      this.focusUser.companyName = this.data.company_name;

      if (this.data.summaries && this.data.summaries.oaic_archetypes) {
        for (const i in this.data.summaries.oaic_archetypes.candidate_avg) {
          this.data.summaries.oaic_archetypes.candidate_avg[i] = Math.round(
            this.data.summaries.oaic_archetypes.candidate_avg[i]
          );
          this.data.summaries.oaic_archetypes.company_avg[i] = Math.round(
            this.data.summaries.oaic_archetypes.company_avg[i]
          );
        }
        //culturePreferenceFocus
        this.culturePreferenceFocus.value = Math.round(
          this.data.summaries.oaic_archetypes.overall
        );
        this.culturePreferenceFocus.candidate =
          this.data.summaries.oaic_archetypes.candidate_avg;
        this.culturePreferenceFocus.company =
          this.data.summaries.oaic_archetypes.company_avg;
      }
    },
    dateFormat(datetime: string) {
      return helpers.dateFormat(datetime);
    },
    donwloadPDF(value: any) {
      console.log("donwloadPDF", value);
    },
    checkPropsId() {
      let valid = true;
      const as_id = this.$router.currentRoute.value.query.as_id;
      const u_id = this.$router.currentRoute.value.query.u_id;
      if (as_id) {
        if (u_id) {
          this.focusUser.id = u_id + "";
        }
      } else {
        valid = false;
        ElMessage({
          message: (this as any).$t("popup.message.message_4"),
          type: "warning",
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }
      return valid;
    },

    score(value: any) {
      return Math.round(value);
    },
    scoreCls(value: any) {
      const score = this.score(value);
      let cls = "red";
      if (score >= 80) {
        cls = "green";
      } else if (score >= 60) {
        cls = "yellow";
      }
      return cls;
    },
  },
});
